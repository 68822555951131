<template>
    <form>
        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>perm_identity</md-icon>
                </div>
                <h4 class="title">
                    {{ $t("Settings.Profile") }}
                </h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>{{ $t("Settings.AcountName") }}</label>
                            <md-input v-model="userName" type="text"></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>{{ $t("Settings.Email") }}</label>
                            <md-input
                                v-model="emailAddress"
                                type="email"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-50 md-size-50">
                        <md-field>
                            <label>{{ $t("Settings.Contact") }}</label>
                            <md-input
                                v-model="phoneNumber"
                                type="text"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-50 md-size-50">
                        <md-field>
                            <label for="movies">{{ $t("Settings.NotifiedStores") }}</label>
                            <md-select v-model="groupToTakeNotice" name="movies" id="movies" multiple>
                                <md-option v-for="group in listGroup" v-bind:key="group.id"
                                    :value="group.id"> 
                                    {{group.name}} 
                                </md-option>
                            </md-select>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button
                            class="md-raised md-success mt-4"
                            @click="updateInfo"
                            >{{ $t("Settings.UpdateAndSave") }}</md-button
                        >
                    </div>
                </div>
            </md-card-content>
        </md-card>

        <md-card>
            <md-card-header class="md-card-header-icon md-card-header-green">
                <div class="card-icon">
                    <md-icon>perm_identity</md-icon>
                </div>
                <h4 class="title">
                    {{ $t("Settings.ChangePassword") }}
                </h4>
            </md-card-header>

            <md-card-content>
                <div class="md-layout">
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>{{ $t("Settings.CurrentPassword") }}</label>
                            <md-input
                                v-model="oldPassword"
                                type="password"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-50">
                        <md-field>
                            <label>{{ $t("Settings.NewPassword") }}</label>
                            <md-input
                                v-model="newPassword"
                                type="password"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-small-size-100 md-size-100">
                        <md-field>
                            <label>{{ $t("Settings.ComfirmNewPassword") }}</label>
                            <md-input
                                v-model="confirmPassword"
                                type="password"
                            ></md-input>
                        </md-field>
                    </div>
                    <div class="md-layout-item md-size-100 text-right">
                        <md-button
                            class="md-raised md-success mt-4"
                            @click="updatePasword"
                            >{{ $t("Settings.ChangePassword") }}</md-button
                        >
                    </div>
                </div>
            </md-card-content>
        </md-card>
        <div style="cursor:pointer" class="language" @click="chooseLanguageOptionInModal()" v-click-outside="handleClickOutSideLanguageInModal">
            <span class="material-icons">language</span>
            {{languageName}}
            <div ref="wrapLanguageOption" class="WrapOption"  style="cursor:pointer">
                <div class="Option" style="cursor:pointer" @click="changeLanguage('en', 'English')">English</div>
                <div class="Option" style="cursor:pointer" @click="changeLanguage('vn', 'Tiếng Việt')">Vietnamese</div>
            </div>
        </div>
    </form>
</template>
<script>
import { Api } from "@/api/index";
import utils from "@/utils";
import EventBus from "@/EventBus";
import updateFirebaseToken from "@/api/userV2/updateFirebaseToken";

const getUserInfo = Api.get("userInfo");
const updateUserInfo = Api.get("updateUserInfo");
const changePassword = Api.get("changePassword");
const getListGroupAPI = Api.get("getListGroupV2")
const getSubscribedNotificationGroup = Api.get("getSubscribedNotificationGroup");
export default {
    name: "edit-profile-form",
    mounted() {
        this.languageName = localStorage.lang == "vn" ? "Tiếng Việt" : "English"
        if (localStorage.lang != "vn") {
            localStorage.setItem("lang", "en")        
        }

        getListGroupAPI.get().then((response) => {
            var listGroup = response.data.listResponse;
            listGroup.unshift({ id: "a09213fhd", name: this.$t("dashboard.All") });
            this.listGroup = listGroup;
        });
        // var groupppp = utils.getGroup()
        // this.groupToTakeNotice = groupppp.split(",")
        this.getSubscribedNotificationGroup()
        this.getInfo();
        this.firebaseToken = localStorage.getItem("firebaseToken")
    },
    data() {
        return {
            languageName: "English",
            lang: localStorage.lang == "vn" ? "vn" : "en", 
            userName: "",
            emailAddress: "",
            phoneNumber: "",
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
            groupToTakeNotice: [],
            listGroup: [],
            firebaseToken: ""
        };
    },
    methods: {
        chooseLanguageOptionInModal() {
            this.$refs["wrapLanguageOption"].style.position = "absolute";
            this.$refs["wrapLanguageOption"].style.display = "block";
            this.$refs["wrapLanguageOption"].style.visibility = "visible";
        },
        handleClickOutSideLanguageInModal() {
            this.$refs["wrapLanguageOption"].style.display = "none";
            this.$refs["wrapLanguageOption"].style.visibility = "hidden";
        },
        changeLanguage(value, valueInVietNamLanguage) {
            var self = this
            setTimeout(() => {
                self.handleClickOutSideLanguageInModal();
            }, 100);
            EventBus.$emit("changeLanguage", value);
            this.languageName = valueInVietNamLanguage
        },
        getClass: function (headerColor) {
            return "md-card-header-" + headerColor + "";
        },
        getInfo() {
            getUserInfo.get().then((response) => {
                this.userName = response.data.data.name;
                this.emailAddress = response.data.data.email;
                this.phoneNumber = response.data.data.phone_number;
            });
        },
        getSubscribedNotificationGroup() {
            getSubscribedNotificationGroup.post()
                .then(response => {
                    this.groupToTakeNotice = response.data["data"].map(group => {
                        return group["id"]
                    })
                })
        },
        updateInfo() {
            const updateFirebaseTokenBody = {
                "firebaseToken": this.firebaseToken,
                "listGroup": this.groupToTakeNotice
            }
            updateFirebaseToken.put(updateFirebaseTokenBody)
            
            var data = {
                name: this.userName,
                email: this.emailAddress,
                phoneNumber: this.phoneNumber,
            };
            this.$swal.showLoading();
            updateUserInfo
                .put(data)
                .then((response) => {
                    this.$swal.close();
                    this.$swal(this.$t("UserResponse."+response.data.message.replace(".","")), "", "success").then(function() {
                        location.reload();
                    });
                })
                .catch((error) => {
                    this.$swal.close();
                    if ( error.response.data.message.length > 1) {
                        this.$swal(this.$t("UserResponse." + error.response.data.message[0].replace(".","")), "", "warning");
                    } else {
                        this.$swal(this.$t("UserResponse." + error.response.data.message.replace(".","")), "", "warning");
                    }
                    
                });
        },
        updatePasword() {
            var data = {
                oldPassword: this.oldPassword,
                newPassword: this.newPassword,
                confirmPassword: this.confirmPassword,
            };
            this.$swal.showLoading();
            changePassword
                .put(data)
                .then((response) => {
                    this.$swal.close();
                    this.$swal(this.$t("UserResponse."+response.data.message.replace(".","")), "", "success");
                })
                .catch((error) => {
                    this.$swal(this.$t("UserResponse."+error.response.data.message.replace(".","")), "", "warning");
                });
        },
    },
};
</script>
<style lang="scss" scoped>
    .language {
        height: 56px;
        display: flex;
        justify-content: space-around;
        border-radius: 8px;
        background-color: #43a047;
        padding: 16px 24px;
        position: relative;
        color: #FFFFFF;
        width: 162px;
        .material-icons {
            margin-right: 8px;
        }
        .WrapOption {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding: 8px;
            max-height: 320px;
            position: absolute;
            width: auto;
            background: #FFFFFF;
            box-shadow: 0px 0px 1px rgba(10, 31, 68, 0.08), 0px 3px 4px rgba(10, 31, 68, 0.1);
            border-radius: 10px;
            top: 60px;
            left: 0;
            visibility: hidden;
            .Option {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                padding: 8px 16px;
                width: 100%;
                height: 36px;
                border-radius: 6px;
                font-family: "Roboto";
                font-size: 14px;
                line-height: 20px;
                color: #333333;
                &:hover {
                    background-color: #F6F9FF;
                }
            }
        }
    }
</style>
