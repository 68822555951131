<template>
    <div class="detail-report-main-container-routemap">
        <div class="detail-report-container" style="margin-bottom: 3%">
            <RouteMapComponent :listGroup="listGroup"> </RouteMapComponent>
        </div>
        <div class="detail-report-container">
            <RouteMapCompareComponent :listGroup="listGroup" >
            </RouteMapCompareComponent>
        </div>
    </div>
</template>

<script>
import { RouteMapComponent, RouteMapCompareComponent } from "@/components";
import { Api } from "@/api/index";

const getListGroup = Api.get("getListGroupV2");
export default {
    components: {
        RouteMapComponent,
        RouteMapCompareComponent,
    },
    data() {
        return {
            listGroup: [],
        };
    },
    mounted() {
        var groupNameAll = localStorage.lang == "vn" ? "Tất cả" : "All"
        getListGroup.get().then((response) => {
            var listGroup = response.data.listResponse;
            listGroup.unshift({ id: "", name: groupNameAll });
            this.listGroup = listGroup;
        });
    },
};
</script>


<style lang="scss">
.detail-report-main-container-routemap {

    .detail-report-title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .detail-select-container {
        display: flex;
        .select-area {
            width: 20%;
            margin-left: auto;
            margin-top: 0;
        }
    }

    .note-conversion-rate {
        padding-left: 25px;
        h4 {
            margin: 0;
            color: #ff6459;
            font-weight: bold;
        }

        ul > li {
            font-size: 15px;
            color: #7f7f7f;
            margin: 20px 0 0 40px;
        }
    }
 }

@media only screen and (max-width: 1280px) {
    .detail-report-main-container-routemap {
        gap: 30px;

        .note-conversion-rate {
            ul > li {
                margin: 13px 0 0 30px;
            }
        }
    }
}
</style>