<template>
    <div class="manage-user-container">
        <div class="manage-user-header">
            <div class="manage-user-title">{{ $t('Settings.Users') }}</div>
            <div class="manage-user-actions">
                <div class="btn-manage-user" @click="showModal">
                    {{ $t('Settings.AddUser') }}
                </div>
            </div>
        </div>
        <div class="manage-user-table">
            <UserTable ref="userTable"/>
        </div>
        <modal name="addSubUser" 
            :width="width"
            :height="height"
            >
            <div>
                <div class="text-center">
                    <h3 class="info-text">{{$t('Settings.AddUser')}}</h3>
                </div>
                <div class="md-layout">
                    <div
                        class="md-layout-item md-size-95 ml-auto mt-4 md-small-size-100"
                    >
                        <md-field >
                            <md-icon>edit</md-icon>
                            <label>{{$t('Settings.Name')}}</label>
                            <md-input v-model="dataInfoUser.name" type="text">
                            </md-input>
                        </md-field>

                        <md-field>
                            <md-icon>mail</md-icon>
                            <label>Email</label>
                            <md-input v-model="dataInfoUser.email" type="text">
                            </md-input>
                        </md-field>
                        <md-field>
                            <md-icon>key</md-icon>
                            <label>{{$t('Settings.Password')}}</label>
                            <md-input v-model="dataInfoUser.password" type="password" autocomplete="off">
                            </md-input>
                        </md-field>
                        <md-field>
                            <md-icon>phone</md-icon>
                            <label>{{$t('Customers.Phone')}}</label>
                            <md-input v-model="dataInfoUser.phoneNumber" type="text">
                            </md-input>
                        </md-field>
                        <div class="md-layout">
                            <div
                                class="md-layout-item md-size-100"
                                style="padding-left: 0%"
                            >
                                <p style="padding-bottom:1px">{{$t('Settings.Store')}}</p>
                            </div>
                            <div v-for="group in listGroup" v-bind:key="group.id" 
                            class="md-layout-item md-size-20">
                                <label :for="group.name">
                                    <input type="checkbox" 
                                        class="form-check-input"
                                        id="science" 
                                        :value="group.id"
                                        v-model="dataInfoUser.listGroup" />{{group.name}}
                                </label>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="text-right button-style">
                    <md-button @click="addUser()" class="md-raised md-success button-style">{{$t('Settings.Add')}}</md-button>
                    <md-button @click="closeModal()" class="md-raised md-danger button-style">{{$t('Settings.Cancel')}}</md-button>
                </div>
            </div>
            
        </modal>
    </div>
</template>

<script>
import { UserTable } from "@/components";
import { Api } from "@/api/index";
import utils from "@/utils";

const getListGroup = Api.get("getListGroupV2");
const addSubUser = Api.get("addSubUser");
export default {
    components: {
        UserTable,
    },
    beforeMount() {
        getListGroup.get().then((response) => {
            this.listGroup = response.data.listResponse;
        });
    },
    data() {
        return {
            isShowModal: false,
            isShowDialogDelete: false,
            idUser: "",
            dataInfoUser: {
                name: "",
                email: "",
                listGroup: [],
                password: "",
                phoneNumber: "",
                isActive: true
            },
            listGroup:[],
            width: window.innerWidth * 0.6,
            height:  window.innerHeight * 0.86,
            // width: 260 * 4,
            // height:  130 * 4,
            groupToInsert: []
        };
    },
    methods: {
        showModal() {
            this.$modal.show(
                'addSubUser',
            )
        },
        closeModal() {
            this.$modal.hide(
                'addSubUser',
            )
        },
        addUser() {
            addSubUser.post(this.dataInfoUser).then(response => {
                this.$modal.hide(
                    'addSubUser',
                )
                this.$swal(
                    this.$t("UserResponse."+response.data.message.replace(".","")),
                    "",
                    "success"
                )
            }).catch( error => {
                this.$modal.hide(
                    'addSubUser',
                )
                this.$swal(this.$t("UserResponse."+error.response.data.message[0].replace(".","")), "", "warning");
            }).then(
                this.$refs.userTable.refresh
            );
        }
    },
};
</script>

<style lang="scss">
.button-style {
    margin-right: 10px;
}
.manage-user-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .manage-user-header {
        display: flex;
        justify-content: space-between;

        .manage-user-title {
            font-size: 30px;
            font-weight: bold;
        }

        .manage-user-actions {
            display: flex;
            gap: 15px;
            font-size: 14px;

            .btn-manage-user {
                width: 140px;
                height: 45px;
                background-color: #4476d9;
                color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #0069d9;
                }
            }
        }
    }

    .manage-user-table {
        background-color: white;
    }
}
</style>
