<template>
    <div class="detail-report-main-container2">
        <div class="detail-select-container">
            <!-- Content 1 -->
            <div class="TitleReport">{{ $t('Report.Report') }}</div>
            <!-- select area -->
            <div class="Detail-search-noti-info">
                <!-- <md-button class="BTN-icon md-icon-button md-accent">
                    <md-icon>notifications</md-icon>
                </md-button> -->
                <div class="avatar">
                    <InfoUserDialog />
                </div>
            </div>
        </div>
         <div class="wraptabs">
            <!-- <div class="wraptabs"> -->
            <button
                class="btn-tabs"
                v-bind:class="{ Overview: activeOverview }"
                style="cursor: pointer"
                @click="OpenTab('overview')"
            >
                {{ $t('Report.Summary') }}
            </button>
            <button
                class="btn-tabs"
                v-bind:class="{ Overview: activeCompare }"
                style="cursor: pointer"
                @click="OpenTab('compare')"
            >
                {{ $t('Report.Comparison') }}
            </button>
            <button
                class="btn-tabs"
                v-bind:class="{ Overview: activeCampaign }"
                style="cursor: pointer"
                @click="OpenTab('campaign')"
            >
                {{ $t('Report.Campaign') }}
            </button>
        </div>
        <div class="WrapTabs" v-if="tabShowing=='overview'">
            <TabReport />
        </div>
        <div class="WrapTabs" v-if="tabShowing=='compare'">
            <BalanceTabReport />
        </div>
    </div>
</template>

<script>
import { InfoUserDialog } from "@/components";
import { TabReport,} from "@/components";
import { BalanceTabReport } from "@/components";
export default {
    name: "detail-report",
    components: {
        InfoUserDialog,
        TabReport,
        BalanceTabReport,
    },
    data() {
        return {
            tabShowing: "overview",
            activeOverview: true,
            activeCampaign: false,
            activeCompare: false
        }
    },
    methods: {
        OpenTab(tabName) {
            this.tabShowing = tabName;
            if (tabName == "overview") {
                this.activeOverview = true;
                this.activeCampaign = false;
                this.activeCompare = false;
            } else if (tabName == "compare") {
                this.activeOverview = false;
                this.activeCampaign = false;
                this.activeCompare = true;
            } else if (tabName == "campaign") {
                this.activeOverview = false;
                this.activeCampaign = true;
                this.activeCompare = false;
            }
        }
    }
};
</script>

<style lang="scss">
.detail-report-main-container2 {
    font-family: "Roboto";
    .detail-select-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .TitleReport {
            font-size: 32px;
            font-weight: 500;
            font-family: "Roboto";
            line-height: 32px;
            color: #000000;
        }
    }
}
.Overview {
    background-color: #EDF3FF !important;
    color: #276eff;
}
</style>
